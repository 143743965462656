import {
  Component,
  OnInit,
  Inject,
  ElementRef,
  ViewChild,
  AfterViewInit,
  OnDestroy,
} from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";

@Component({
  selector: "app-playlist-previewer",
  templateUrl: "./preview-playlist-modal.component.html",
  styleUrls: ["./preview-playlist-modal.scss"],
})
export class PlaylistPreviewModalComponent implements AfterViewInit, OnDestroy {
  @ViewChild("flutterIframe") flutterIframe: ElementRef<HTMLIFrameElement>;
  private messageListenerAdded: boolean = false;
  private messageListener: any;

  iframeUrl: string = "https://halogensigntech.com/previewer";
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<PlaylistPreviewModalComponent>,
  ) {
    //
  }
  ngOnDestroy(): void {
    if (this.messageListenerAdded) {
      window.removeEventListener("message", this.messageListener);
      this.data.playlist = null;
    }
  }

  handleClose(): void {
    this.dialogRef.close();
  }

  ngAfterViewInit() {
    this.addMessageListener();
  }

  addMessageListener(): void {
    var self = this;
    if (!this.messageListenerAdded) {
      this.messageListener = window.addEventListener(
        "message",
        function (event) {
          if (event.origin + '/previewer' === self.iframeUrl) {
            if (event.data === "onPreviewContainerLoaded") {
              if (self.data.playlist) {
                const flutterIframe = document.getElementById(
                  "flutterIframe",
                ) as HTMLIFrameElement;
                const flutterWindow = flutterIframe.contentWindow;
                if (flutterWindow) {
                  flutterWindow.postMessage(self.data.playlist, "*");
                }
              }
            }
          }
        },
      );

      this.messageListenerAdded = true;
    }
  }
}
