export const innerDrawerMenus = [
  {
    id: 1,
    label: "Location",
    path: "/manage/location",
    icon: "assets/ic-common.png",
  },
  {
    id: 2,
    label: "Players",
    path: "/manage/players",
    icon: "assets/ic-common.png",
  },
  {
    id: 3,
    label: "Groups",
    path: "/manage/player-groups",
    icon: "assets/ic-common.png",
  },
  {
    id: 4,
    label: "Dimension",
    path: "/manage/dimensions",
    icon: "assets/ic-common.png",
  },
  {
    id: 6,
    label: "Layout Editor",
    path: "/manage/zone-editor",
    icon: "assets/ic-common.png",
  },
  {
    id: 11,
    label: "Scene Editor",
    path: "/manage/scene-editor",
    icon: "assets/ic-common.png",
  },
  {
    id: 12,
    label: "Playlists",
    path: "/manage/playlists",
    icon: "assets/ic-common.png",
  },
  {
    id: 13,
    label: "Scheduler",
    path: "/manage/scheduler",
    icon: "assets/ic-common.png",
  },
  {
    id: 14,
    label: "User Roles",
    path: "/manage/user-role",
    icon: "assets/ic-common.png",
  },
  {
    id: 15,
    label: "Users",
    path: "/manage/users",
    icon: "assets/ic-common.png",
  },
];
