import { Component, OnChanges, Output, EventEmitter, Input } from "@angular/core";
import { AssetModalWidgetComponent } from "../assets/assets.modal.component";
import { MatDialog } from "@angular/material/dialog";

@Component({
  selector: "app-pdf-widget",
  templateUrl: "./pdf.widget.component.html",
  styleUrls: ["./widget.scss"],
})
export class PdfWidgetComponent implements OnChanges {
  @Output() onSaveProps = new EventEmitter();
  @Output() onAddAsset = new EventEmitter();
  @Input() widget: Object;

  widgetProps = {
    name: "PDF ",
    src: "",
    type: "internal",
    backgroundColor: "#333"
  };

  constructor(public dialog: MatDialog) {}

  ngOnChanges() {
    this.widgetProps = {
      name:
        (this.widget["props"] && this.widget["props"]["name"]) ||
        "PDF " + this.widget["index"],
      src: (this.widget["props"] && this.widget["props"]["src"]) || "",
      type: (this.widget["props"] && this.widget["props"]["type"]) || "internal",
      backgroundColor:
        (this.widget["props"] && this.widget["props"]["backgroundColor"]) || "#333"
    };

    this.onSaveProps.emit({
      props: this.widgetProps,
      showAlert: false,
    });
  }

  openAssetsModal() {
    const dialogRef = this.dialog.open(AssetModalWidgetComponent, {
      width: "900px",
      data: {
        assetType: 'docs'
      }
    });

    dialogRef.afterClosed().subscribe((assetObj) => {
      if (assetObj && assetObj.mediaUrl !== '') {
        this.widgetProps.src = assetObj.mediaUrl;
        this.onAddAsset.emit(assetObj.mediaUrl);
      }
    });
  }

  saveWidgetProps() {
    this.onSaveProps.emit({
      props: this.widgetProps,
      showAlert: true,
    });
  }
}
