import { Component, OnInit, ChangeDetectorRef } from "@angular/core";
import {
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from "@angular/forms";
import { Router, ActivatedRoute } from "@angular/router";
import { MatTableDataSource } from "@angular/material/table";
import { MatDialog } from "@angular/material/dialog";
import * as _ from "underscore";
import { NgxSpinnerService } from "ngx-spinner";

import { SceneModalComponent } from "../modal/scene-modal.component";
import { DimensionService } from "../../../../manage/pages/dimensions/services/dimensions.service";
import { PlayListService } from "../services/playlist.service";
import { CustomModalComponent } from "src/app/shared/components/custom-modal/custom-modal.component";
import { PlaylistPreviewModalComponent } from "../preview-playlist-modal/preview-playlist-modal.component";

@Component({
  selector: "app-playlist-form",
  templateUrl: "./form.component.html",
  styleUrls: ["./form.component.scss"],
})
export class PlaylistFormComponent implements OnInit {
  crumbItems: Array<Object>;
  displayedColumns: string[] = [
    "name",
    "dimension",
    "duration",
    "scene_sort",
    "durationReq",
    "action",
  ];
  id: any;
  saveBtnText: string = "Submit";
  pageTitle: string = "Add New - Playlist";

  myForm = new UntypedFormGroup({
    name: new UntypedFormControl("", [
      Validators.required,
      Validators.minLength(5),
    ]),
    dimension: new UntypedFormControl("", [Validators.required]),
    description: new UntypedFormControl("", [Validators.required]),
  });

  dataSource = new MatTableDataSource();
  dimensions: Array<Object>;
  selectedScenes: Array<Object> = [];
  sortOrder: Array<string> = [];
  durationRequired: Array<string> = [];
  data: Object = {};
  nameAlreadyAssigned: boolean = false;
  playlistDetail: Object = {};
  sortedOrder: Array<string> = [];

  constructor(
    public dialog: MatDialog,
    private _dservice: DimensionService,
    private _service: PlayListService,
    private _route: Router,
    private route: ActivatedRoute,
    private spinner: NgxSpinnerService,
    private changeDetectorRef: ChangeDetectorRef,
  ) {}

  async ngOnInit() {
    this.crumbItems = [
      {
        label: "Dashboard",
        path: null,
        current: false,
      },
      {
        label: "Playlists",
        path: "manage/playlists",
        current: true,
      },
    ];

    this.id = this.route.snapshot.params.id;
    this.saveBtnText = this.id ? "Update" : "Save";
    this.pageTitle = this.id && "Update - Playlist";

    let dimenstionRes = await this._dservice.getDimensions(1, 10000);
    this.dimensions = dimenstionRes["data"];

    if (this.id) {
      this.spinner.show();
      this._service.getPlaylist(this.id).then((res) => {
        this.playlistDetail = res["data"];
        this.myForm.get("name").setValue(res["data"]["name"]);

        let dimension = this.dimensions.filter((obj) => {
          return obj["name"] === res["data"]["dimension"];
        });

        let dimensionObj =
          (dimension && dimension.length && dimension[0]) || {};

        this.myForm.get("dimension").setValue(dimensionObj);
        this.myForm.get("description").setValue(res["data"]["description"]);

        this.dataSource.data = this.selectedScenes =
          res["data"]["scenes"] || [];

        this.data = res["data"];
        this.sortedOrder = res["data"]["scene_sort"];
        this.spinner.hide();
      });
    }
  }

  showSceneModal(): void {
    const dialogRef = this.dialog.open(SceneModalComponent, {
      width: "70%",
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        if (this.selectedScenes.length > 0) {
          let dataSourceObj = this.selectedScenes;
          dataSourceObj = [...dataSourceObj, ...result];
          this.selectedScenes = dataSourceObj;
        } else {
          this.selectedScenes = result;
        }

        this.dataSource.data = this.selectedScenes = _.uniq(
          this.selectedScenes,
          function (x) {
            return x._id;
          },
        );

        this.sortedOrder = [];
      }
    });
  }

  onRemoveSelectedScene(elem: any) {
    const dialogRef = this.dialog.open(CustomModalComponent, {
      width: "400px",
      data: {
        title: "Confirm Deletion!",
        content: "Do you want to delete this entry from listing ?",
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.dataSource.data = [];
        let _selectedScenes = [];
        _selectedScenes = _.filter(this.selectedScenes, (scene: any, index: number) => {
          this.playlistDetail['scene_durations'] = this.playlistDetail['scene_durations'].splice(index, 1);
          return scene._id !== elem._id;
        });

        this.dataSource.data = _selectedScenes;
        this.selectedScenes = _selectedScenes;
        this.sortedOrder = [];
      }
    });
  }

  onSave(): void {
    let postBody: Object = {};
    let scenes: Array<string> = [];
    let duration: number = 0;

    if (this.selectedScenes.length === 0) {
      this.dialog.open(CustomModalComponent, {
        width: "400px",
        data: {
          title: "No Scenes Added!",
          content: "Hey! Please add some scenes to this playlist and save",
        },
      });
    } else {
      var sortOrder = document.getElementsByClassName("sort-order-required");
      var durationReq = document.getElementsByClassName("duration-required");

      for (var i = 0; i < sortOrder.length; i++) {
        if (sortOrder[i]["value"] != "" && sortOrder[i]["value"]) {
          this.sortOrder.push(sortOrder[i]["value"]);
        }
      }

      for (var i = 0; i < durationReq.length; i++) {
        if (durationReq[i]["value"] != "" && durationReq[i]["value"]) {
          this.durationRequired.push(durationReq[i]["value"]);
        }
      }

      if (this.sortOrder.length == 0 || this.durationRequired.length == 0) {
        this.dialog.open(CustomModalComponent, {
          width: "400px",
          data: {
            title: "No Priorities Set!",
            content: "Please set the sort order and duration for the scenes",
          },
        });
      } else {
        scenes = this.selectedScenes.map((elem) => {
          return elem["_id"];
        });

        this.durationRequired.forEach((elem) => {
          duration = duration + parseInt(elem);
        });

        postBody = {
          name: this.myForm.value.name,
          dimension: this.myForm.value.dimension["name"],
          width: this.myForm.value.dimension["width"],
          height: this.myForm.value.dimension["height"],
          description: this.myForm.value.description,
          duration,
          scenes,
          scene_sort: this.sortOrder,
          scene_duration: this.durationRequired,
        };

        if (this.id) {
          this.setPlaylist(postBody);
        } else {
          this._service
            .isPlaylistExists({ name: this.myForm.value.name })
            .then((res) => {
              if (res["OK"]) {
                this.setPlaylist(postBody);
                this.nameAlreadyAssigned = false;
              } else {
                this.nameAlreadyAssigned = true;
              }
              this.changeDetectorRef.detectChanges();
            });
        }
      }
    }
  }

  setPlaylist(postBody) {
    this.spinner.show();
    if (this.id) {
      this._service.updatePlaylist(postBody, this.id).then((res) => {
        if (res["status"] === 200) {
          this._route.navigate(["/manage/playlists"]);
        } else {
          alert(res["message"]);
        }
        this.spinner.hide();
      });
    } else {
      this._service.setPlaylist(postBody).then((res) => {
        if (res["status"] === 200) {
          this._route.navigate(["/manage/playlists"]);
        } else {
          alert(res["message"]);
        }
        this.spinner.hide();
      });
    }
  }

  handlePreviewScenes() {
    if (this.selectedScenes.length && this.myForm.value.dimension["name"]) {
      let totalPlaylistDuration = 0;
      let durationReq = document.getElementsByClassName("duration-required");
      let sortOrder = document.getElementsByClassName("sort-order-required");

      let sceneData = _.sortBy(
        this.selectedScenes.map((scene: Object, index: any) => {
          return {
            ...scene,
            ...{ sort_order: parseInt(sortOrder[index]["value"]) },
          };
        }),
        function (o: any) {
          return o.sort_order;
        },
      );

      for (var i = 0; i < durationReq.length; i++) {
        if (durationReq[i]["value"]) {
          totalPlaylistDuration =
            totalPlaylistDuration + parseInt(durationReq[i]["value"]);
        }
      }

      this.dialog.open(PlaylistPreviewModalComponent, {
        width: "100%",
        disableClose: true,
        data: {
          playlist: {
            _id: "playlistPreviewer",
            name: "testPlaylistPreviewer",
            dimension: this.myForm.value.dimension["name"],
            duration: totalPlaylistDuration,
            height: this.myForm.value.dimension["height"],
            width: this.myForm.value.dimension["width"],
            weekdays: [],
            playlist_count: 1,
            has_expiry: false,
            repeat: true,
            has_touch: false,
            is_dummy_playlist_screen: false,
            scenes: sceneData,
            playlistId: null,
          },
        },
      });
    } else {
      this.dialog.open(CustomModalComponent, {
        width: "400px",
        data: {
          title: "No Scenes Added!",
          content: "Please select dimension and add some scenes to preview the final playlist",
        },
      });
    }
  }
}
