<div class="row">
  <div class="col-xs-12">
    <h2>PDF Widget Properties</h2>
    <hr class="widget-header mt0 mb0" />
  </div>
</div>
<div class="row mt30">
  <div class="col-xs-12 mb10">
    <mat-form-field appearance="legacy">
      <mat-label>PDF Widget Name</mat-label>
      <input
        matInput
        placeholder="PDF Widget Name"
        [(ngModel)]="widgetProps.name"
      />
    </mat-form-field>
  </div>
  <div class="col-xs-12 mb10">
    <mat-form-field appearance="legacy">
      <mat-select placeholder="Source Link" [(ngModel)]="widgetProps.type">
        <mat-option value="internal">Asset Library</mat-option>
        <mat-option value="external">External Link</mat-option>
      </mat-select>
    </mat-form-field>
  </div>
  <div class="col-xs-12 mb10" *ngIf="widgetProps.type === 'external'">
    <mat-form-field appearance="legacy">
      <mat-label>External Link</mat-label>
      <input
        matInput
        placeholder="http://www.example.com/love.jpg"
        [(ngModel)]="widgetProps.src"
      />
    </mat-form-field>
  </div>
  <div class="col-xs-12 mb10" *ngIf="widgetProps.type === 'internal'">
    <mat-label class="widget-mat-label">Media Library</mat-label>
    <p class="label">
      If you have already uploaded the file in asset library please pick the
      same.
    </p>
    <button class="widget-button" mat-button (click)="openAssetsModal()">
      Choose from Media Library
    </button>
  </div>
  <div class="col-xs-12 mt10 mb10">
    <mat-label class="widget-mat-label">BG Color</mat-label>
    <div class="clearfix"></div>
    <input
      [(colorPicker)]="widgetProps.backgroundColor"
      [style.background]="widgetProps.backgroundColor"
      class="color-picker"
    />
  </div>

  <div class="col-xs-12 mt35">
    <button
      class="widget-button"
      mat-button
      color="primary"
      (click)="saveWidgetProps()"
    >
      Save Widget
    </button>
  </div>
</div>