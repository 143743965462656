<div class="row">
  <div class="col-xs-12">
    <h2>Text Widget Properties</h2>
    <hr class="widget-header mt0 mb0" />
  </div>
</div>
<div class="row mt30">
  <div class="col-xs-12 mb10">
    <mat-form-field appearance="legacy">
      <mat-label>Name</mat-label>
      <input
        matInput
        placeholder="Widget Name"
        [(ngModel)]="widgetProps.name"
      />
    </mat-form-field>
  </div>
  <div class="col-xs-12 mb10">
    <mat-form-field appearance="legacy">
      <mat-label>Text</mat-label>
      <input matInput placeholder="Label" [(ngModel)]="widgetProps.text" />
    </mat-form-field>
  </div>
  <div class="col-xs-12 mb10">
    <mat-form-field appearance="legacy">
      <mat-select
        placeholder="Font Family"
        [(ngModel)]="widgetProps.fontFamily"
      >
        <mat-option value="Arial, sans-serif">Arial</mat-option>
        <mat-option value="Open Sans, sans-serif">Open Sans</mat-option>
        <mat-option value="Calibri">Calibri</mat-option>
        <mat-option value="Verdana, sans-serif">Verdana</mat-option>
        <mat-option value="Tahoma, sans-serif">Tahoma</mat-option>
        <mat-option value="'Trebuchet MS', sans-serif">Trebuchet MS</mat-option>
        <mat-option value="Calibri">Calibri</mat-option>
      </mat-select>
    </mat-form-field>
  </div>
  <div class="col-xs-6 mb10">
    <mat-form-field appearance="legacy">
      <mat-label>Font Size</mat-label>
      <input
        type="number"
        matInput
        placeholder="Font Size"
        [(ngModel)]="widgetProps.fontSize"
      />
    </mat-form-field>
  </div>
  <div class="col-xs-6 mb10">
    <mat-form-field appearance="legacy">
      <mat-select placeholder="Alignment" [(ngModel)]="widgetProps.align">
        <mat-option value="center">Center</mat-option>
        <mat-option value="flex-start">Left</mat-option>
        <mat-option value="flex-end">Right</mat-option>
      </mat-select>
    </mat-form-field>
  </div>
  <div class="col-xs-6 mb10">
    <mat-form-field appearance="legacy">
      <mat-select
        placeholder="Vertical Alignment"
        [(ngModel)]="widgetProps.valign"
      >
        <mat-option value="center">Middle</mat-option>
        <mat-option value="flex-start">Top</mat-option>
        <mat-option value="flex-end">Bottom</mat-option>
      </mat-select>
    </mat-form-field>
  </div>
  <div class="col-xs-12 mb10">
    <mat-label>Text Style</mat-label>
    <div class="clearfix"></div>
    <mat-checkbox
      color="primary"
      class="pr10 cb-font"
      [(ngModel)]="widgetProps.bold"
      >Bold</mat-checkbox
    >
    <mat-checkbox
      color="primary"
      class="pr10 cb-font"
      [(ngModel)]="widgetProps.fontStyle"
      >Italic</mat-checkbox
    >
    <mat-checkbox
      color="primary"
      class="pr10 cb-font"
      [(ngModel)]="widgetProps.underline"
      >Underline</mat-checkbox
    >
  </div>
  <div class="col-xs-12 mb10">
    <mat-label class="widget-mat-label"
      >Choose Widget Background (Image only)</mat-label
    >
    <p class="normal-text">
      If you have already uploaded the file in media library please pick the
      same.
    </p>
    <button class="widget-button" mat-button (click)="openAssetsModal()">
      Choose from Media Library
    </button>
  </div>
  <div class="col-xs-6 mt10 mb10">
    <mat-label class="widget-mat-label">Text Color</mat-label>
    <div class="clearfix"></div>
    <input
      [(colorPicker)]="widgetProps.color"
      [style.background]="widgetProps.color"
      class="color-picker"
    />
  </div>
  <div class="col-xs-6 mt10 mb10">
    <mat-label class="widget-mat-label">BG Color</mat-label>
    <div class="clearfix"></div>
    <input
      [(colorPicker)]="widgetProps.backgroundColor"
      [style.background]="widgetProps.backgroundColor"
      class="color-picker"
    />
  </div>
  <div class="col-xs-12 mt15">
    <mat-label class="widget-mat-label">Position</mat-label>
    <div class="row mt15">
      <div class="col-xs-6">
        <mat-form-field class="example-full-width">
          <input
            type="number"
            matInput
            placeholder="Left"
            [(ngModel)]="widgetProps.coords.left"
          />
          <span matSuffix>px &nbsp;</span>
        </mat-form-field>
      </div>
      <div class="col-xs-6">
        <mat-form-field class="example-full-width">
          <input
            type="number"
            matInput
            placeholder="Top"
            [(ngModel)]="widgetProps.coords.top"
          />
          <span matSuffix>px &nbsp;</span>
        </mat-form-field>
      </div>
    </div>
  </div>
  <div class="col-xs-12 mt35">
    <button
      class="widget-button"
      mat-button
      color="primary"
      (click)="saveWidgetProps()"
    >
      Save Widget
    </button>
  </div>
</div>