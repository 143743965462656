import { Component, ViewChild  } from "@angular/core";
import { MatTabChangeEvent } from "@angular/material/tabs";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridWeek from "@fullcalendar/timegrid";
import timeGridDay from "@fullcalendar/timegrid"; 
import { MatDialog } from "@angular/material/dialog";
import * as moment from 'moment';
import { FullCalendarComponent } from '@fullcalendar/angular';
import { NgxSpinnerService } from 'ngx-spinner';

import { SchedulerModal } from "./modal/scheduler-modal.component";
import { SchedulerPlayerGroupModal } from "./modal-player-groups/scheduler-modal.component";
import { SchedulerService } from "./services/scheduler.service";
import { CustomModalComponent } from "../../../../../shared/components/custom-modal/custom-modal.component";

@Component({
  selector: "app-scheduler",
  templateUrl: "./scheduler.component.html",
  styleUrls: ["./scheduler.component.scss"],
})
export class SchedulerComponent {
  tabComponents: Array<Object>;
  currentTab: Object;
  calendarPlugins = [dayGridPlugin, timeGridWeek, timeGridDay];
  tabData: Array<Object> = [];
  totalCount: Number = 0;
  searchQry: string = '';

  events: any = []; 
  @ViewChild('calendar', {static: true}) calendarComponent: FullCalendarComponent;

  constructor(public dialog: MatDialog, private _service: SchedulerService, private spinner: NgxSpinnerService) {
    this.tabComponents = [
      {
        id: 1,
        label: "Players",
        query: "players",
      },
      {
        id: 2,
        label: "Player Groups",
        query: "playergroups",
      },
    ];
    this.currentTab = this.tabComponents[0];

    this.getTabComponents(this.currentTab["query"], 1, 1000);
  }

  getTabComponents(query, page, limit, searchQry = '') {
    this.spinner.show();
    this._service.getTabDataByItem(query, page, limit, searchQry).then((res) => {
      this.tabData = res["data"];
      this.totalCount = res["count"];
      this.spinner.hide();
    });
  }

  handleDatesRender($event) {

    let postBody = {
      start: moment($event.view.currentStart).format('YYYY-MM-DD'),
      end: moment($event.view.currentEnd).format('YYYY-MM-DD'),
      query: this.currentTab["query"],
      duration: String($event.view.viewSpec.durationUnit)
    };

    this.getCalendarEvents(postBody);
  }

  populateCalendarEvents() {
    let postBody = {
      start: moment(this.calendarComponent.getApi().view.currentStart).format('YYYY-MM-DD'),
      end: moment(this.calendarComponent.getApi().view.currentEnd).format('YYYY-MM-DD'),
      query: this.currentTab["query"],
      duration: String(this.calendarComponent.getApi().view.viewSpec.durationUnit)
    };

    this.getCalendarEvents(postBody);
  }

  getCalendarEvents(postBody) {
    this.spinner.show();
    this._service.getEventSchedules(postBody).then(res => {
      this.events = res['data'];
      this.spinner.hide();
    });
  }

  tabChange(tab: MatTabChangeEvent) {
    this.currentTab = this.tabComponents[tab.index];
    this.getTabComponents(this.currentTab["query"], 1, 1000);
    this.populateCalendarEvents();
  }

  onSearch() {
    this.getTabComponents(this.currentTab["query"], 1, 1000, this.searchQry);
  }

  openDialog() {

    let dialogRef;

    if(this.currentTab["query"] === 'players') {
      dialogRef = this.dialog.open(SchedulerModal, {
        width: "800px",
        data: {
          currentTab: this.currentTab,
        },
      });
 
    } else {
      dialogRef = this.dialog.open(SchedulerPlayerGroupModal, {
        width: "800px",
        data: {
          currentTab: this.currentTab,
        },
      });
    }

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.getTabComponents(this.currentTab["query"], 1, 1000);
        this.populateCalendarEvents();
      }
    });
  }

  deleteIdsArray(arrayOfIds) {
    let postBody = {
      ids: arrayOfIds,
    };

    this._service.deleteEvent(postBody).then((res) => {
      if (res["status"] === 200) {
        this.getTabComponents(this.currentTab["query"], 1, 1000);
        this.populateCalendarEvents();
      }
    });
  }

  deletePlaylistFromEvent(postObj) {
    let postBody = {
      ids: [postObj.playListId],
      event_id: postObj.eventId,
    };

    this._service.deleteEventPlaylist(postBody).then((res) => {
      if (res["status"] === 200) {
        this.getTabComponents(this.currentTab["query"], 1, 1000);
        this.populateCalendarEvents();
      }
    });
  }

  onDeleteEvent(eventId) {
    const dialogRef = this.dialog.open(CustomModalComponent, {
      width: "400px",
      data: {
        title: "Confirm Event Deletion!",
        content: "Do you want to delete this event from listing?",
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.deleteIdsArray([eventId]);
      }
    });
  }

  _populateCalendarEventsByPlayer(playerId) {
    let postBody = {
      start: moment(this.calendarComponent.getApi().view.currentStart).format('YYYY-MM-DD'),
      end: moment(this.calendarComponent.getApi().view.currentEnd).format('YYYY-MM-DD'),
      query: this.currentTab["query"],
      duration: String(this.calendarComponent.getApi().view.viewSpec.durationUnit),
      playerId: playerId
    };

    this.getCalendarEvents(postBody);
  }
  
  onViewPlayerEvents(playerId) {
    this._populateCalendarEventsByPlayer(playerId);
  }

  onDeletePlaylist(event) {
    const dialogRef = this.dialog.open(CustomModalComponent, {
      width: "400px",
      data: {
        title: "Confirm Playlist Deletion!",
        content: "Do you want to delete this playlist from this event?",
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.deletePlaylistFromEvent(event);
      }
    });
  }

  eventRender(event, element) {
    if (event.color) {
      element.css('background-color', event.color)
    }
  }
}
