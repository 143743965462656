<!-- Data cell comes here -->
<div class="card lg">
  <h3>Total Duration</h3>
  <h2>2hrs 20mins</h2>
  <small>Content</small>
</div>
<div class="card">
  <h3>Playlists</h3>
  <h2>64</h2>
  <small>Added</small> <img src="assets/playlist-cell.png" alt="" />
</div>
<div class="card">
  <h3>Active</h3>
  <h2>24</h2>
  <small>Online</small> <img src="assets/playlist-cell.png" alt="" />
</div>
<div class="clearfix"></div>
<app-module-header
  title="All Playlists"
  [isBackEnabled]="false"
  [menuItems]="bulkMenuItems"
  (onMenuClicked)="onMenuClicked($event)"
  [showBulkMode]="true"
  [showAddButton]="true"
  (onSearch)="onSearch($event)"
  (onAdd)="onAdd()"
></app-module-header>
<div class="clearfix"></div>
<div class="tableWrapper">
  <table width="100%" mat-table [dataSource]="dataSource">
    <ng-container matColumnDef="_id">
      <th width="40" mat-header-cell *matHeaderCellDef mat-sort-header>
        <mat-checkbox
          color="primary"
          (change)="doSelectAll()"
          [checked]="isAllChecked()"
        ></mat-checkbox>
      </th>
      <td mat-cell *matCellDef="let element">
        <mat-checkbox
          color="primary"
          [checked]="isChecked(element._id)"
          (change)="doSelect(element._id)"
        >
        </mat-checkbox>
      </td>
    </ng-container>
    <ng-container matColumnDef="name">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Name</th>
      <td mat-cell *matCellDef="let element">
        <h2>{{ element.name }}</h2>
      </td>
    </ng-container>
    <ng-container matColumnDef="dimension">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>
        Max Supported Dim.
      </th>
      <td mat-cell *matCellDef="let element">
        {{ element.dimension + " : " + element.width + " x " + element.height }}
      </td>
    </ng-container>
    <ng-container matColumnDef="duration">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Duration</th>
      <td mat-cell *matCellDef="let element">
        {{ toHoursMinutesSeconds(element.duration) }}
      </td>
    </ng-container>
    <ng-container matColumnDef="scene_count">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Total Scenes</th>
      <td mat-cell *matCellDef="let element">{{ element.scene_count }}</td>
    </ng-container>
    <ng-container matColumnDef="action">
      <th mat-header-cell *matHeaderCellDef mat-sort-header class="text-right">
        Action
      </th>
      <td mat-cell *matCellDef="let element" class="text-right">
        <button
          mat-button
          class="mat-button-custom"
          (click)="onPreviewPlaylist(element._id)"
          title="Preview"
        >
          <img src="assets/icon_preview.png" alt="" width="20" />
        </button>
        <button
          mat-button
          class="mat-button-custom"
          (click)="onEditPlaylist(element._id)"
          title="Edit"
        >
          <img src="assets/edit.png" alt="" width="16" />
        </button>
        <button
          mat-button
          class="mat-button-custom"
          (click)="openDialog(element._id)"
          title="Delete"
        >
          <img src="assets/delete.png" alt="" width="16" />
        </button>
      </td>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
  </table>
</div>
<div class="clearfix"></div>
<app-paginate
  *ngIf="dataSource.data.length > 0"
  [length]="totalCount"
  pageSize="10"
  (onPageChange)="onPageChange($event)"
></app-paginate>
