<app-breadcrumbs [crumbItems]="crumbItems"></app-breadcrumbs>
<app-module-header
  title="Player Groups"
  isBackEnabled="false"
  [menuItems]="bulkMenuItems"
  (onMenuClicked)="onMenuClicked($event)"
  showBulkMode="true"
  showAddButton="true"
  (onSearch)="onSearch($event)"
  (onAdd)="onAdd()"
></app-module-header>
<div class="clearfix"></div>
<div class="tableWrapper">
  <table width="100%" mat-table [dataSource]="dataSource">
    <ng-container matColumnDef="_id">
      <th width="40" mat-header-cell *matHeaderCellDef mat-sort-header>
        <mat-checkbox
          color="primary"
          (change)="doSelectAll()"
          [checked]="isAllChecked()"
        ></mat-checkbox>
      </th>
      <td mat-cell *matCellDef="let element">
        <mat-checkbox
          color="primary"
          [checked]="isChecked(element._id)"
          (change)="doSelect(element._id)"
        ></mat-checkbox>
      </td>
    </ng-container>
    <ng-container matColumnDef="name">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Group Name</th>
      <td mat-cell *matCellDef="let element">
        <h2>{{ element.name }}</h2>
      </td>
    </ng-container>
    <ng-container matColumnDef="count">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Players Count</th>
      <td mat-cell *matCellDef="let element">{{ element.count }}</td>
    </ng-container>
    <ng-container matColumnDef="location">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Location</th>
      <td mat-cell *matCellDef="let element">{{ element.location }}</td>
    </ng-container>
    <ng-container matColumnDef="action">
      <th mat-header-cell *matHeaderCellDef mat-sort-header class="text-right">
        Action
      </th>
      <td mat-cell *matCellDef="let element" class="text-right">
        <button
          mat-button
          class="mat-button-custom"
          (click)="onEditGroup(element._id)"
          title="Edit"
        >
          <img src="assets/edit.png" alt="" width="16" />
        </button>
        <button
          mat-button
          class="mat-button-custom"
          (click)="openDialog(element._id)"
          title="Delete"
        >
          <img src="assets/delete.png" alt="" width="16" />
        </button>
      </td>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
  </table>
</div>
<div class="clearfix"></div>
<app-paginate
  [length]="totalCount"
  pageSize="10"
  (onPageChange)="onPageChange($event)"
></app-paginate>
