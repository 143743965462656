<div class="row">
  <div class="col-xs-12">
    <h2>Video Widget Properties</h2>
    <hr class="widget-header mt0 mb0" />
  </div>
</div>
<div class="row mt30">
  <div class="col-xs-12 mb10">
    <mat-form-field appearance="legacy">
      <mat-label>Video Name</mat-label>
      <input
        matInput
        placeholder="Video Name"
        [(ngModel)]="vidWidgetProps.name"
      />
    </mat-form-field>
  </div>
  <div class="col-xs-12 mb10">
    <mat-label class="widget-mat-label">Media Library</mat-label>
    <p class="label">
      If you have already uploaded the file in asset library please pick the
      same.
    </p>
    <button class="widget-button" mat-button (click)="openAssetsModal()">
      Choose from Media Library
    </button>
  </div>
  <div class="col-xs-12 mt10 mb10" style="height: 50px">
    <mat-label class="widget-mat-label">BG Color</mat-label>
    <div class="clearfix"></div>
    <input
      [(colorPicker)]="vidWidgetProps.backgroundColor"
      [style.background]="vidWidgetProps.backgroundColor"
      class="color-picker"
    />
  </div>
  <div class="col-xs-6 mb10">
    <mat-form-field appearance="legacy">
      <mat-select placeholder="Autoplay" [(ngModel)]="vidWidgetProps.autoplay">
        <mat-option value="true">Yes</mat-option>
        <mat-option value="false">No</mat-option>
      </mat-select>
    </mat-form-field>
  </div>
  <div class="col-xs-6 mb10">
    <mat-form-field appearance="legacy">
      <mat-select placeholder="Loop Video" [(ngModel)]="vidWidgetProps.loop">
        <mat-option value="true">Yes</mat-option>
        <mat-option value="false">No</mat-option>
      </mat-select>
    </mat-form-field>
  </div>
  <div class="col-xs-6 mb10">
    <mat-form-field appearance="legacy">
      <mat-select
        placeholder="Show Controls"
        [(ngModel)]="vidWidgetProps.controls"
      >
        <mat-option value="true">Yes</mat-option>
        <mat-option value="false">No</mat-option>
      </mat-select>
    </mat-form-field>
  </div>
  <div class="col-xs-6 mb10">
    <mat-form-field appearance="legacy">
      <mat-select placeholder="Mute Video" [(ngModel)]="vidWidgetProps.muted">
        <mat-option value="true">Yes</mat-option>
        <mat-option value="false">No</mat-option>
      </mat-select>
    </mat-form-field>
  </div>
  <div class="clearfix"></div>
  <div class="col-xs-6 mb10">
    <mat-form-field appearance="legacy">
      <input matInput placeholder="Width" [(ngModel)]="vidWidgetProps.width" />
    </mat-form-field>
  </div>
  <div class="col-xs-6 mb10">
    <mat-form-field appearance="legacy">
      <mat-select placeholder="Fit" [(ngModel)]="vidWidgetProps.widthUnit">
        <mat-option value="px">px</mat-option>
        <mat-option value="%">%</mat-option>
      </mat-select>
    </mat-form-field>
  </div>
  <div class="col-xs-6 mb10">
    <mat-form-field appearance="legacy">
      <mat-select placeholder="Alignment" [(ngModel)]="vidWidgetProps.align">
        <mat-option value="internal">Center</mat-option>
        <mat-option value="external">Left</mat-option>
        <mat-option value="external">Right</mat-option>
      </mat-select>
    </mat-form-field>
  </div>
  <div class="col-xs-6 mb10">
    <mat-form-field appearance="legacy">
      <mat-select placeholder="Fit" [(ngModel)]="vidWidgetProps.fit">
        <mat-option value="fill">Stretch</mat-option>
        <mat-option value="contain">Contain</mat-option>
        <mat-option value="cover">Aspect Ratio Fit</mat-option>
      </mat-select>
    </mat-form-field>
  </div>
  <div class="col-xs-12">
    <mat-slide-toggle color="primary" [(ngModel)]="vidWidgetProps.hasBorder">
      Border
    </mat-slide-toggle>
  </div>
  <div class="col-xs-4 mt10 mb10" *ngIf="vidWidgetProps.hasBorder">
    <mat-label class="widget-mat-label">Choose Color</mat-label>
    <div class="clearfix"></div>
    <input
      [(colorPicker)]="vidWidgetProps.borderColor"
      [style.background]="vidWidgetProps.borderColor"
      class="color-picker"
    />
  </div>
  <div class="col-xs-4 mt10 mb10" *ngIf="vidWidgetProps.hasBorder">
    <mat-form-field class="example-full-width">
      <input
        matInput
        placeholder="Border width"
        [(ngModel)]="vidWidgetProps.borderWidth"
      />
      <span matSuffix>px &nbsp;</span>
    </mat-form-field>
  </div>
  <div class="col-xs-12 mt15">
    <mat-label class="widget-mat-label">Position</mat-label>
    <div class="row mt15">
      <div class="col-xs-6">
        <mat-form-field class="example-full-width">
          <input
            type="number"
            matInput
            placeholder="Left"
            [(ngModel)]="vidWidgetProps.coords.left"
          />
          <span matSuffix>px &nbsp;</span>
        </mat-form-field>
      </div>
      <div class="col-xs-6">
        <mat-form-field class="example-full-width">
          <input
            type="number"
            matInput
            placeholder="Top"
            [(ngModel)]="vidWidgetProps.coords.top"
          />
          <span matSuffix>px &nbsp;</span>
        </mat-form-field>
      </div>
    </div>
  </div>
  <div class="col-xs-12 mt35">
    <button
      class="widget-button"
      mat-button
      color="primary"
      (click)="saveWidgetProps()"
    >
      Save Widget
    </button>
  </div>
</div>
