<div class="modal-content">
  <div class="modal-header">
    <div class="pull-left">
      <h2>New Scene</h2>
    </div>
  </div>
  <div class="clearfix"></div>
  <div class="modal-body" mat-dialog-content>
    <form [formGroup]="myForm">
      <div class="row">
        <div class="col-xs-12">
          <div class="row mb10">
            <div class="col-xs-12">
              <mat-form-field class="example-full-width">
                <input
                  matInput
                  placeholder="Name"
                  name="name"
                  formControlName="name"
                />
                <mat-error *ngIf="myForm.get('name').hasError('required')"
                  >Field is required</mat-error
                >
                <mat-error *ngIf="myForm.get('name').hasError('minLength')"
                  >Min length is 5</mat-error
                >
              </mat-form-field>
            </div>
          </div>
          <div class="row">
            <div class="col-xs-12">
              <mat-form-field>
                <mat-select
                  placeholder="Supported Dimension"
                  (selectionChange)="onDimensionChange($event)"
                  name="dimension"
                  formControlName="dimension"
                >
                  <mat-option [value]="item" *ngFor="let item of dimensions">{{
                    item.name
                  }}</mat-option>
                </mat-select>
                <mat-error *ngIf="myForm.get('dimension').hasError('required')"
                  >Field is required</mat-error
                >
              </mat-form-field>
            </div>
          </div>
          <div class="row mb20">
            <div class="col-xs-12">
              <mat-label>Choose Layout</mat-label>
              <div class="clearfix"></div>
              <div class="row mt10" *ngIf="filteredLayouts.length > 0">
                <div class="col-xs-12">
                  <div class="scollX">
                    <div class="scroll-holder">
                      <a
                        *ngFor="let item of filteredLayouts"
                        class="scene-grid-item"
                        (click)="doSelectLayout(item)"
                        [ngClass]="{ active: layout_id == item._id }"
                      >
                        <img
                          [src]="item.thumbnail"
                          alt="Layout thumbnail"
                          width="100%"
                        />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row mt10" *ngIf="filteredLayouts.length === 0">
                <div class="col-xs-12">
                  <div class="help-block alert-info pl10 pr10 pt10 pb10">
                    No Layouts has been found for
                    {{ myForm.get("dimension").value }}
                  </div>
                </div>
              </div>
              <div class="clearfix"></div>
              <div
                class="help-block"
                [ngClass]="{
                  'alert-danger': !isFormValid && filteredLayouts.length > 0
                }"
              >
                Please choose one layout and continue
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
  <div mat-dialog-actions>
    <button mat-flat-button color="secondary" [mat-dialog-close]="false">
      Cancel
    </button>
    <button
      mat-flat-button
      color="primary"
      [disabled]="!myForm.valid"
      (click)="onSave()"
    >
      Create
    </button>
  </div>
</div>