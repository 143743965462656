<div class="row mb5">
  <div class="col-xs-12">
    <h1 class="mt0">Dashboard</h1>
  </div>
</div>
<div class="card lg">
  <h3>Storage</h3>
  <h2>24GB</h2>
  <small>Used</small>
  <div class="storage-info">
    <span>Used 2Gb / 5Gb</span>
    <div class="storage-graph">
      <div class="storage-used-graph"></div>
    </div>
  </div>
</div>
<div class="card">
  <h3>Players</h3>
  <h2>{{ playersLength }}</h2>
  <small>Added</small> <img src="assets/ic-player.png" alt="" />
</div>
<div class="card">
  <h3>Online</h3>
  <h2>{{ onlinePlayers.length }}</h2>
  <small>As of Now</small> <img src="assets/ic-player.png" alt="" />
</div>
<div class="card">
  <h3>Offline</h3>
  <h2>{{ offlinePlayers.length }}</h2>
  <small>As of Now</small> <img src="assets/ic-player.png" alt="" />
</div>
<!-- <div class="card highlight">
  <h3>New Player</h3>
  <h2>5</h2>
  <small>Need to be setup</small>
  <img
    src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAwCAYAAABXAvmHAAAACXBIWXMAAAsTAAALEwEAmpwYAAAFK0lEQVRoge2aW2xUVRSG/z29WWhVUIuQWouOLRIQK8SAVtFIpGpI1BdDog/6gMEoD6iJKIoakRA0MfGKMYYHo/Jg1MQoRIEaNPGCRi4Br9iKxUYRaqGghfL5sFdhcpxzmZlTGxJWMpnMWf/+1977nP2vvfYZ6QQ3lzYh4CTVSaoIuAYkdTvnSDtmagZMBX4i3HYDM9OMmeodALZJ2iXpQUn7A+5qSYslXSGp3jl3NM3YJRtQbbN8ZQSm2TCNacXNpEUkqcy+D0Zg+uy7PK2gaQ5gWOzkAIbbQp9FICPpOklNktY557YkwCa12UCnc+5wBGeTpDZJv0p6zznXn5gdcMArwEFgmynHG0BDsOPA7cC3wBFgFRBMYEHeZ4C/gU5gAVAewIwBXgaOAjuAXuBdoCyMN1+gG61D0+x3K7DJBvQoMAIYC6y1ayuAcwrgH2M8PcBnQBaoAu63Du8A2gybBfqAeUnJT7Fs+mzg+uBsdwO/AH8AW4CJiWfmv7HOBT4FDgA7gX12VyoCuMUW7/QkpIuAPcDoEH8t8ITNenWxnc/hKwcesUfrzBBMNfAz8HQc2TibjfmldixtA24GDgPNUaAVtmiTL5j/yUwANgCrcq8HZXSKpI3OuYESg9XLy+p4+S1Gp6Q1zrmdxXI65wDaJd0UFfhh4C+gtZggQAOw2mR3N7Ae+NAkE+B9YEKR3C3Ab8BzUaAq4FUL9nqB0jjdlOJHYBY+uQ36HHAZsBXYD8wugLcuJy+8DdQkadQKfGUavwQYEYNvNOV6ExgZgasCXjChmBTDWQnca0/EsbyQ2Ez37zDd7wRqI7BvAT9EdT4HW4FPiu348jMfptxyzF7gnmBeKHQgU+yRagrxN5j/6gI4J1ubS0L8p5n/mjiuJLvRUfbdFeKfLWm3pPYEXJIk59xWSZsl3RAC6ZV0QFJs5k0ygLMl9Tjn+kL84yVtL+K0YYekxnwO4+qy2JGWZAAZSVEFeFmMP8wGFF1aDihB/5IMoFvSaML3PZ2SmsMWZIQ1SeqI8Ndb7NIMmGQL6vwQ/wXmv7QAzqy1mRHirzX/VUV2+1jymWPyuBcYFYFdC2wGKhPwZkxCv8xNdgFMlUn3LmBuwXcXmGidGgBeBM6KwU+wDPt8lGZb5x8D/om7Y8CpwHKgH/gEK67iOp4BluGrsXXARbGNjrdtswy7CZicx5+1me8H5hbAmwXewW8lXoq8y8BC4BB+713wsaOtl3Z7fr/B19GvAV/YtU3A9EJ5jXsWvmJblnvdBUBrJXU45+4sJohxOEkt8kmqUV4qOyStkfR5KWeiwOOS5jjnWgavBXX4e0kzgEyxgSwJfW2ftG2afB/zG3Ae/sjj1iEIXpIB15qoXBwHXAp0EbLvxu8mFwD3kULpaXI9D3iAkG27xdwOrExCWIOvppbm8V2PP8TqwZ/VtOPLx2I7fwb+0KofXwx1ArcEBcQmrCdOznMb3GaPUrP9vhD4IDcvAE2mKn/iD6Tiz2yO848E7rI7vR1fLubq/kZgqmHHmvosLGRmMqa9e4CP8McZ64N5AV81LQJ+txzwFCHZNafNQ/jMvg9/vjQi4M/V/Q02yI8j9T8kUCW+IltuGhyaF/AHT3eb1uctUgw3zjBLiKjwDHs58CQwP2w9pmq2diAi5QP1hsmmFfeEfz9wcgDDbWkOYHDrURWBGfSVdHQ5ZIZ/r7Da1KYm8KkDVpqEpvaaNVUDZlpGDbNeYE6aMYfizx5VkpolBRPPEUnfOecOpR3zhLZ/Ad0IDrxnuW7oAAAAAElFTkSuQmCC"
    alt=""
  />
</div> -->
<!-- <div class="clearfix"></div>
<div class="row">
  <div class="col-xs-12">
    <div class="row">
      <div class="col-xs-8">
        <div class="row">
          <div class="col-xs-12">
            <h2 class="mb5">Player - Analytics View</h2>
          </div>
        </div>
        <div class="clearfix"></div>
        <div style="display: block">
          <canvas
            baseChart
            height="150"
            [datasets]="lineChartData"
            [labels]="lineChartLabels"
            [options]="lineChartOptions"
            [colors]="lineChartColors"
            [legend]="lineChartLegend"
            [chartType]="lineChartType"
            [plugins]="lineChartPlugins"
          ></canvas>
        </div>
      </div>
      <div class="col-xs-4">
        <div class="row">
          <div class="col-xs-12">
            <h2 class="mb5">Geolocation Based Details</h2>
          </div>
        </div>
        <div class="clearfix"></div>
        <div class="row">
          <div class="col-xs-12">
            <ul class="geograph-data">
              <li>United Arab Emirates (<strong>Imp: 12,400</strong>)</li>
              <li>Qatar (<strong>Imp: 6,400</strong>)</li>
              <li>Saudi Arabia (<strong>Imp: 8,400</strong>)</li>
              <li>United State of America (<strong>Imp: 25,450</strong>)</li>
              <li>Australia (<strong>Imp: 12,400</strong>)</li>
              <li>India (<strong>Imp: 12,400</strong>)</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</div> -->
<div class="clearfix"></div>
<div class="row mt15 mb15">
  <div class="col-xs-6">
    <div class="well">
      <div class="row">
        <div class="col-xs-12">
          <h2 class="mt0 mb10">Running Players</h2>
        </div>
      </div>
      <div class="row">
        <div class="col-xs-12">
          <div class="tableWrapper">
            <table width="100%" mat-table [dataSource]="dataSource">
              <ng-container matColumnDef="playerId">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>
                  <strong>Player ID</strong>
                </th>
                <td mat-cell *matCellDef="let element">
                  {{ element.playerId }}
                </td>
              </ng-container>
              <ng-container matColumnDef="location">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>
                  <strong>Location</strong>
                </th>
                <td mat-cell *matCellDef="let element">
                  {{ element.location || "Nil" }}
                </td>
              </ng-container>
              <ng-container matColumnDef="created_on">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>
                  <strong>Added On</strong>
                </th>
                <td mat-cell *matCellDef="let element">
                  {{ element.created_on | date: "mediumDate" }}
                </td>
              </ng-container>
              <ng-container matColumnDef="status">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>
                  <strong>Status</strong>
                </th>
                <td mat-cell *matCellDef="let element">
                  {{ element.is_online ? "Online" : "Offline" }}
                </td>
              </ng-container>
              <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
              <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="col-xs-6">
    <div class="well">
      <div class="row">
        <div class="col-xs-12">
          <h2 class="mt0 mb10">Online Playlists</h2>
        </div>
      </div>
      <div class="row">
        <div class="col-xs-12">
          <div class="tableWrapper">
            <table width="100%" mat-table [dataSource]="dataSourcePlaylists">
              <ng-container matColumnDef="name">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>
                  <strong>Name</strong>
                </th>
                <td mat-cell *matCellDef="let element">{{ element.name }}</td>
              </ng-container>
              <ng-container matColumnDef="dimension">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>
                  <strong>Dimension</strong>
                </th>
                <td mat-cell *matCellDef="let element">
                  {{ element.dimension || "Nil" }}
                </td>
              </ng-container>
              <ng-container matColumnDef="duration">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>
                  <strong>Duration</strong>
                </th>
                <td mat-cell *matCellDef="let element">
                  {{ element.duration | minuteSeconds }}
                </td>
              </ng-container>
              <ng-container matColumnDef="scene_count">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>
                  <strong>Scenes</strong>
                </th>
                <td mat-cell *matCellDef="let element">
                  {{ element.scene_count }}
                </td>
              </ng-container>
              <tr
                mat-header-row
                *matHeaderRowDef="displayedColumnsPlaylist"
              ></tr>
              <tr
                mat-row
                *matRowDef="let row; columns: displayedColumnsPlaylist"
              ></tr>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
