<div class="modal-content" mat-dialog-content>
  <div class="modal-header">
    <div class="pull-left">
      <h2>Media Library - {{ data.assetType }}</h2>
    </div>
    <div class="pull-right">
      <div class="searchBar">
        <mat-form-field class="example-full-width">
          <input type="text" matInput placeholder="Search" />
          <mat-icon matSuffix>search</mat-icon>
        </mat-form-field>
      </div>
    </div>
  </div>
  <div class="clearfix"></div>
  <div class="sceneModalFilter">
    <div class="pull-left filter">
      <mat-label>Dimension</mat-label>
      <mat-select [ngModel]="dimension" (ngModelChange)="onApplyFilter($event)">
        <mat-option value="all">All</mat-option>
        <mat-option *ngFor="let item of dimensions" [value]="item._id"
          >{{item.name}}</mat-option
        >
      </mat-select>
    </div>
    <div class="pull-left filter">
      <mat-label>Sort By</mat-label>
      <mat-select [ngModel]="sortby" (ngModelChange)="onApplySort($event)">
        <mat-option value="date">Date</mat-option>
        <mat-option value="name">Name</mat-option>
        <mat-option value="size">Size</mat-option>
      </mat-select>
    </div>
  </div>
  <div class="clearfix"></div>
  <div>
    <mat-grid-list cols="12" rowHeight="160" gutterSize="12px">
      <mat-grid-tile
        *ngFor="let d of dataArray"
        colspan="3"
        class="matGridTile"
        [mat-dialog-close]="d"
      >
        <a
          class="linkContent"
          *ngIf="d.mimetype == 'video/mp4'"
          [ngStyle]="{'background': 'url(' + d.thumbnail + ') no-repeat', 'background-size': 'cover'}"
        ></a>
        <a
          class="linkContent"
          *ngIf="d.mimetype === 'application/pdf'"
          [ngStyle]="{'background': 'url(' + d.thumbnail + ') no-repeat', 'background-size': 'cover'}"
        ></a>
        <a
          class="linkContent"
          *ngIf="d.mimetype != 'video/mp4' && d.mimetype !== 'application/pdf'"
          [ngStyle]="{'background': 'url(' + d.mediaUrl + ') no-repeat', 'background-size': 'cover'}"
        ></a>
        <mat-grid-tile-footer>
          <div class="flexStart">
            <h4>{{d.originalname}}</h4>
            <small
              >{{ d.size | formatFileSize}} &nbsp;&nbsp;&nbsp;{{d.created_on |
              date: 'mediumDate'}}</small
            >
          </div>
        </mat-grid-tile-footer>
      </mat-grid-tile>
    </mat-grid-list>
  </div>
  <div mat-dialog-actions>
    <app-paginate
      [length]="totalCount"
      pageSize="10"
      (onPageChange)="onPageChange($event)"
    ></app-paginate>
  </div>
</div>
