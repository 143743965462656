<div class="modal-content" aria-hidden="false" aria-modal="true">
  <div class="modal-header">
    <div class="pull-left">
      <h2>Preview Playlist</h2>
      <small
        >Just see the actual view of the current playlist in the device,
        here</small
      >
    </div>
    <div class="pull-right">
        <button mat-flat-button class="closePreview" color="secondary" (click)="handleClose()">Close Preview</button>
    </div>
  </div>
  <div class="modal-content" style="padding: 0px; margin-top: 16px">
    <div class="aspect-ratio-16-9">
      <iframe
        class="previewFrame"
        #flutterIframe
        id="flutterIframe"
        src="https://halogensigntech.com/previewer"
      ></iframe>
    </div>
  </div>
</div>
