import { Component, OnInit } from "@angular/core";
import { MatTableDataSource } from "@angular/material/table";
import { PageEvent } from "@angular/material/paginator";
import { Router } from "@angular/router";
import * as _ from "underscore";
import { MatDialog } from "@angular/material/dialog";
import { NgxSpinnerService } from 'ngx-spinner';

import { PlayersService } from "../services/players.service";
import { CustomModalComponent } from "src/app/shared/components/custom-modal/custom-modal.component";

@Component({
  selector: "app-players-list",
  templateUrl: "./list.component.html",
  styleUrls: ["./list.component.scss"],
})
export class PlayersListComponent implements OnInit {
  crumbItems: Array<Object>;
  bulkMenuItems: Array<string>;
  displayedColumns: string[] = [
    "_id",
    "name",
    "dimension",
    "playerId",
    "location",
    "timezone",
    "storage",
    "status",
    "action",
  ];
  data: Array<Object>;
  totalCount: number;
  dataSource = new MatTableDataSource();
  checkedList: Array<string> = [];
  searchQry: string = '';
  pageSize: number = 10;
  currentPage: number = 1;

  ngOnInit() {
    this.crumbItems = [
      {
        label: "Dashboard",
        path: null,
        current: false,
      },
      {
        label: "Players",
        path: "manage/players",
        current: true,
      },
    ];
    this.bulkMenuItems = ["Delete"];
    const savedPageSize = localStorage.getItem('pageSize');
    const savedPageIndex = localStorage.getItem('pageIndex') || '1';

    this.pageSize = parseInt(savedPageSize);
    this.currentPage = parseInt(savedPageIndex);
    this.getAllPlayers(this.currentPage, this.pageSize);
  }

  constructor(
    private _router: Router,
    private _service: PlayersService,
    public dialog: MatDialog,
    private spinner: NgxSpinnerService
  ) {}

  getAllPlayers(page, limit, searchQuery = '') {
    this.spinner.show();
    this._service.getPlayers(page, limit, searchQuery).then((res) => {
      this.dataSource.data = res["data"];
      this.totalCount = res["count"];
      this.spinner.hide();
    });
  }

  onPageChange(event: PageEvent) {
    this.pageSize = event.pageSize;
    localStorage.setItem('pageSize', event.pageSize.toString());
    localStorage.setItem('pageIndex', event.pageIndex.toString());
    this.getAllPlayers(++event.pageIndex, event.pageSize, this.searchQry);
  }

  doEditPlayer(playerId): void {
    this._router.navigate(["/manage/players/edit/" + playerId]);
  }

  onMenuClicked(index: number) {
    switch (index) {
      case 0:
        this.deleteIdsArray(this.checkedList);
        break;
      default:
    }
  }

  onApprovePlayer(playerId): void {
    this._router.navigate(["/manage/players/approve/" + playerId]);
  }

  isChecked(id) {
    return this.checkedList.includes(id);
  }

  isAllChecked() {
    return this.checkedList.length === this.dataSource.data.length;
  }

  doSelect(id) {
    if (this.isChecked(id)) {
      this.checkedList = _.without(this.checkedList, id);
    } else {
      this.checkedList.push(id);
    }
  }

  doSelectAll() {
    if (this.checkedList.length === 0) {
      this.checkedList = this.dataSource.data.map((elem, index) => elem["_id"]);
    } else {
      this.checkedList = [];
    }
  }

  openDialog(id) {
    const dialogRef = this.dialog.open(CustomModalComponent, {
      width: "400px",
      data: {
        title: "Confirm Deletion!",
        content:
          "Do you want to delete this entry from listing?  You can keep the license for this particular player",
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.deleteIdsArray([id]);
      }
    });
  }

  deleteIdsArray(arrayOfIds) {
    let postBody = {
      ids: arrayOfIds,
    };
    this.spinner.show();
    this._service.deletePlayer(postBody).then((res) => {
      if (res["status"] !== 200) {
        alert(res["message"]);
      }
      this.getAllPlayers(this.currentPage, this.pageSize);
      this.spinner.hide();
    });
  }

  onAdd() {
    this._router.navigate(["/manage/players/add/"]);
  }

  showStatus(status: Boolean) {
    return status ? 'Online' : 'Offline';
  }

  onSearch(event: string) {
    this.searchQry = event;
    this.getAllPlayers(1, 1000, event);
  }

  handleShowCode(code: string, event: any) {
    this.spinner.show();
    this._service.setShowCodeTrigger(code).then(() => {
      this.spinner.hide();
    }).catch(err => {
      alert(err.error.message);
      this.spinner.hide();
    }).finally(() => {
      this.spinner.hide();
    });
  }
}
