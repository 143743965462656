<app-breadcrumbs [crumbItems]="crumbItems"></app-breadcrumbs>
<app-scene-editor-header
  [dataState]='dataState'
  (sceneInfoEvent)="sceneInfoEvent($event)"
  (saveScene)="onSaveScene()"
>
</app-scene-editor-header>

<div class="clearfix"></div>
<div class="row" *ngIf="!isLoading">
  <div class="col-xs-9">
    <div class="scene-editor-wrapper">
      <div class="canvas-wrapper" id="canvasWrapperScene"
        [ngStyle]="{'width': canvas['canvasWidth'] + 'px', 'height': canvas['canvasHeight'] + 'px', 'overflow': 'hidden', 'background-color': sceneInfo['backgroundColor']}">
        <div class="object" id="object-{{i}}" [attr.data-widget]="getWidget(i)" (click)="handleClick(i)"
          *ngFor="let item of canvas['objects']; let i = index"
          [ngStyle]="{'width': item.scaleX * item.width + 'px', 'height': item.scaleY * item.height + 'px', 'left': item.left + 'px', 'top': item.top + 'px'}">
          <div style="height: 100%; width: 100%; position: absolute;" *ngFor="let widget of widgetArray">
            <div style="height: 100%; width: 100%; position: absolute;" *ngIf="widget">
            <button style="position: absolute; top: 10px; right: 10px; background: #f91c1c; border: none; z-index: 200"
              (click)="removeWidget($event, widget.widget, i)" *ngIf="widget.index === i && widget.widget !== 'clock'">
              <img src="assets/trash.svg" style="width: 14px" />
            </button>
            <div style="height: 100%; width: 100%; position: absolute;"
              *ngIf="widget.index === i && widget.widget !== 'clock' && !widget.props">

              <div
                style="display: flex; width: 100%; height: 100%; justify-content: center; align-items: center; align-content: center; background: #fff;">
                <img [src]="getWidgetImage(widget.widget)" width="10%" style="max-width: 30%" />
              </div>
            </div>

            <!-- Image -->
            <div style="display: flex; height: 100%; width: 100%; position: relative;"
              *ngIf="widget.index === i && widget.props && widget.widget == 'image'">
              <div style="height: 100%; width: 100%; position: relative;"
                [ngStyle]="{'textAlign': widget.props.align !='' ? widget.props.align : 'left' , 'background-image': 'url(' + getAssetItemIdFromUrl(widget.props.src) + ')', 'background-size': widget.props.fit, 'background-repeat': 'no-repeat', 'border': widget.props.hasBorder ? 'solid ' + widget.props.borderColor + ' ' + widget.props.borderWidth + 'px' : 'none', 'padding-left': widget.props.coords.left + 'px', 'padding-top': widget.props.coords.top + 'px', 'background-position-x': widget.props.coords.left + 'px', 'background-position-y': widget.props.coords.top + 'px'}">
              </div>
            </div>

            <!-- FIFA Widget -->
            <div style="display: flex; height: 100%; width: 100%; position: relative;"
              *ngIf="widget.index === i && widget.props && widget.widget == 'fifa'">
              <div style="height: 100%; width: 100%; position: relative;"
                [ngStyle]="{'background-image': 'url(' + getAssetItemIdFromUrl(widget.props.backgroundImage) + ')', 'background-repeat': 'no-repeat', 'background-size': widget.props.fit}">
                <div style="display: block; position: absolute; top: 4rem; left: 2.5rem; width: 120px; height: 84px; background: transparent;">
                  <img [src]="widget.props.teamASrc" style="width: 100%;">
                  <h2 style="margin: 6px 0; color: #fff; text-transform: uppercase; font-size: 1.8rem; font-weight: bold;">{{widget.props.teamA ? widget.props.teamA : ''}}</h2>
                  <h4 [ngStyle]="{'font-size': widget.props.goalFontSize}"  style="font-weight: bold; margin: 0; color: #fff; padding-top: 20px;">4</h4>
                </div>
                <div style="display: block; position: absolute; top: 4rem; left: 16.5rem; width: 150px; height: 24vh; background: #268dd1; border-radius: 6px; opacity: 0.8;"></div>
                <div style="display: block; position: absolute; top: 4rem; right: 2.5rem; width: 120px; height: 84px; background: transparent;">
                  <img [src]="widget.props.teamBSrc" style="width: 100%;">
                  <h2 style="margin: 6px 0; color: #fff; text-transform: uppercase; font-size: 1.8rem; font-weight: bold;">{{widget.props.teamB ? widget.props.teamB : ''}}</h2>
                  <h4 [ngStyle]="{'font-size': widget.props.goalFontSize}"  style="font-weight: bold; margin: 0; color: #fff; padding-top: 20px;">4</h4>
                </div>
              </div>
            </div>

            <!-- HTML Viewer -->
            <div style="display: flex; height: 100%; width: 100%; position: relative;"
              *ngIf="widget.index === i && widget.props && widget.widget == 'html-viewer'">
              <iframe id="html-viewer-widget-iframe"
                style="border: none; width: 100%; height:100%; display: block; position: absolute; top: 0px; left: 0px;"
                [src]="widget.props.url | safeUrl" [title]="widget.props.name">
              </iframe>
            </div>

            <!-- Video -->
            <div style="height: 100%; width: 100%; position: absolute;"
              *ngIf="widget.index === i && widget.props && widget.widget == 'video'">
              <div style="height: 100%; width: 100%; position: absolute;"
                [ngStyle]="{'textAlign': widget.props.align !='' ? widget.props.align : 'left' , 'border': widget.props.hasBorder ? 'solid ' + widget.props.borderColor + ' ' + widget.props.borderWidth + 'px' : 'none', 'background-color': widget.props.backgroundColor, 'background-image': 'url(' + getAssetItemIdFromUrl(widget.props.src) + ')', 'background-repeat': 'no-repeat', 'background-size': 'cover',  'padding-left': widget.props.coords.left + 'px', 'padding-top': widget.props.coords.top + 'px'}">
                <video style="display: none;" class="videoContainers" [ngStyle]="{'width': widget.props.width + widget.props.widthUnit}" controls>
                  <source [src]="widget.props.video" type="video/mp4">
                  Your browser does not support the video tag.
                </video>
              </div>
            </div>

            <!-- Text -->
            <div style="height: 100%; width: 100%; position: absolute;"
              *ngIf="widget.index === i && widget.props && widget.widget == 'text'">
              <div style="display: flex; height: 100%; width: 100%; position: absolute;"
                [ngStyle]="{'justify-content': widget.props.align !='' ? widget.props.align : 'start' , 'align-items': widget.props.valign !='' ? widget.props.valign : 'start', 'background-image': 'url(' + getAssetItemIdFromUrl(widget.props.backgroundSrc) + ')', 'background-size': 'cover', 'background-repeat': 'no-repeat', 'padding-left': widget.props.coords.left + 'px', 'padding-top': widget.props.coords.top + 'px', 'background-color': widget.props.backgroundColor }">
                <p [class]="widget.props.name"
                  [ngStyle]="{'font-family': widget.props.fontFamily, 'font-size': widget.props.fontSize + 'px', 'color': widget.props.color, 'font-weight': widget.props.bold ? 'bold' : 'normal', 'font-style': widget.props.fontStyle ? 'italic' : 'normal', 'text-decoration': widget.props.underline ? 'underline' : 'none'}" style="word-break: break-all; line-height: 100%;">
                  {{getUnescapedHtml(widget.props.text)}}
                </p>
              </div>
            </div>

            <!-- Marquee -->
            <div style="height: 100%; width: 100%; position: absolute;"
              *ngIf="widget.index === i && widget.props && widget.widget == 'marquee'">
              <div style="height: 100%; width: 100%; position: absolute;"
                [ngStyle]="{'textAlign': widget.props.align !='' ? widget.props.align : 'left', 'padding-left': widget.props.coords.left + 'px', 'padding-top': widget.props.coords.top + 'px', 'background-color': widget.props.bgcolor }">
                
                <marquee width="100%" [attr.direction]="widget.props.direction" [attr.scrolldelay]="widget.props.scrollDelay" [attr.scrollamount]="widget.props.scrollAmount" [attr.behavior]="widget.props.behavior">
                  <p [class]="widget.props.name"
                    [ngStyle]="{'font-family': widget.props.fontFamily, 'font-size': widget.props.fontSize + 'px', 'color': widget.props.color, 'font-weight': widget.props.bold ? 'bold' : 'normal', 'font-style': widget.props.fontStyle ? 'italic' : 'normal', 'text-decoration': widget.props.underline ? 'underline' : 'none'}">
                    {{getUnescapedHtml(widget.props.scrollText)}}
                  </p>
                </marquee>
              </div>
            </div>

            <!-- Buttton -->
            <div style="height: 100%; width: 100%; position: absolute;"
              *ngIf="widget.index === i && widget.props && widget.widget == 'button'">
              <div style="height: 100%; width: 100%; position: absolute;"
                [ngStyle]="{'textAlign': widget.props.align !='' ? widget.props.align : 'left' , 'background-image': 'url(' + getAssetItemIdFromUrl(widget.props.backgroundSrc) + ')', 'background-size': 'cover', 'background-repeat': 'no-repeat', 'padding-left': widget.props.coords.left + 'px', 'padding-top': widget.props.coords.top + 'px'}">
                <button [class]="widget.props.name"
                  [ngStyle]="{'padding': '16px 20px', 'font-size': widget.props.fontSize + 'px', 'background-color': widget.props.fillColor, 'font-weight': widget.props.bold ? 'bold' : 'normal', 'border': widget.props.hasBorder ? 'solid ' + widget.props.borderColor + ' ' + widget.props.borderWidth + 'px' : 'none', 'display': 'inline-block', 'position': 'relative', 'width': widget.props.width + 'px', 'height': widget.props.height + 'px', 'color': widget.props.textColor }"
                  *ngIf="widget.props.action !== 'launch_external_url'">
                  {{widget.props.label}}
                </button>
                <a [href]="widget.props.action_url" [class]="widget.props.name"
                  [ngStyle]="{'padding': '16px 20px', 'font-size': widget.props.fontSize + 'px', 'background-color': widget.props.fillColor, 'font-weight': widget.props.bold ? 'bold' : 'normal', 'border': widget.props.hasBorder ? 'solid ' + widget.props.borderColor + ' ' + widget.props.borderWidth + 'px' : 'none', 'display': 'inline-block', 'position': 'relative', 'width': widget.props.width + 'px', 'height': widget.props.height + 'px', 'color': widget.props.textColor }"
                  *ngIf="widget.props.action === 'launch_external_url'">
                  {{widget.props.label}}
                </a>
              </div>
            </div>

            <!-- Clock widget comes here-->
            <div style="height: 100%; width: 100%; position: absolute;"
              *ngIf="widget.index === i && widget.widget === 'clock'">
              <button
                style="position: absolute; top: 10px; right: 10px; background: #f91c1c; border: none; z-index: 200"
                (click)="removeWidget($event, widget.widget, i)">
                <img src="assets/trash.svg" style="width: 14px" />
              </button>

              <div style="height: 100%; width: 100%; position: absolute;"
                [ngStyle]="{'padding-left': widget.props.coords.left + 'px', 'padding-top': widget.props.coords.top + 'px'}">
                <app-clock-widget [backgroundColor]='widget.props.backgroundColor' [hrFormat]='widget.props.hrFormat' [timeZone]='widget.props.timeZone'>
                </app-clock-widget>
              </div>
            </div>
            <!-- Clock widget comes here-->

            <!-- Slideshow widget comes here-->
            <div style="height: 100%; width: 100%; position: absolute;"
              *ngIf="widget.index === i && widget.props && widget.widget == 'slideshow'">
              <div style="height: 100%; width: 100%; position: absolute;">
                <div class="widget-slideshow-preview">
                  <p>{{ widget.props.images.length }} Images Added</p>
                  <div class="slide-pager">
                      <div class="slide-pager-wrapper" *ngFor="let item of widget.props.images"></div>
                  </div>
                  <button mat-button color="primary" (click)="previewSlideShow(widget.props)">
                    Preview
                  </button>
                </div>
              </div>
            </div>

            <!-- PDF widget comes here-->
            <div style="height: 100%; width: 100%; position: absolute;"
              *ngIf="widget.index === i && widget.props && widget.widget == 'pdf'">
              <div style="height: 100%; width: 100%; position: absolute;">
                <ngx-doc-viewer
                  [url]="widget.props.src"
                  viewer="google"
                  style="width: 100%; height: 100%"
                ></ngx-doc-viewer>
              </div>
            </div>
          </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="col-xs-3" style="overflow-y: scroll; height: calc(100vh - 200px);">
      <app-image-widget (onAddAsset)="onAddAsset($event)" (onSaveProps)="onSaveProps($event)" [widget]='activeWidget'
        *ngIf="activeWidget['widget'] === 'image'"></app-image-widget>
      <app-pdf-widget (onAddAsset)="onAddAsset($event)" (onSaveProps)="onSaveProps($event)" [widget]='activeWidget'
        *ngIf="activeWidget['widget'] === 'pdf'"></app-pdf-widget>
      <app-video-widget (onAddAsset)="onAddAsset($event)" (onSaveProps)="onSaveProps($event)" [widget]='activeWidget'
        *ngIf="activeWidget['widget'] === 'video'"></app-video-widget>
      <app-text-widget (onAddAsset)="onAddAsset($event)" (onSaveProps)="onSaveProps($event)" [widget]='activeWidget'
        *ngIf="activeWidget['widget'] === 'text'">
      </app-text-widget>
      <app-button-widget (onAddAsset)="onAddAsset($event)" (onSaveProps)="onSaveProps($event)" [widget]='activeWidget'
        *ngIf="activeWidget['widget'] === 'button'"></app-button-widget>
      <app-marquee-widget (onAddAsset)="onAddAsset($event)" (onSaveProps)="onSaveProps($event)" [widget]='activeWidget'
        *ngIf="activeWidget['widget'] === 'marquee'"></app-marquee-widget>
      <app-slideshow-widget (onRemoveAsset)="onRemoveAsset($event)" (onAddAsset)="onAddAsset($event)" (onSaveProps)="onSaveProps($event)" [widget]='activeWidget'
        *ngIf="activeWidget['widget'] === 'slideshow'"></app-slideshow-widget>
      <app-clock-widget-props (onSaveProps)="onSaveProps($event)" [widget]='activeWidget'
        *ngIf="activeWidget['widget'] === 'clock'"></app-clock-widget-props>
      <app-html-viewer-widget (onSaveProps)="onSaveProps($event)" [widget]='activeWidget'
        *ngIf="activeWidget['widget'] === 'html-viewer'"></app-html-viewer-widget>
      <app-scene-widget (onSaveProps)="onSaveSceneProps($event)" [sceneInfo]='sceneWidget' *ngIf="activeWidget['widget'] === 'scene'"></app-scene-widget>
      <!-- <app-fifa-widget (onAddAsset)="onAddAsset($event)" (onSaveProps)="onSaveProps($event)" [widget]='activeWidget'
        *ngIf="activeWidget['widget'] === 'fifa'"></app-fifa-widget> -->
  </div>
</div>