<app-module-header
  title="Layouts"
  isBackEnabled="false"
  showBulkMode="false"
  showAddButton="true"
  (onAdd)="onAdd()"
  (onSearch)="onSearch($event)"
></app-module-header>
<div class="clearfix"></div>
<div class="tableWrapper">
  <table width="100%" mat-table [dataSource]="dataSource">
    <ng-container matColumnDef="id">
      <th width="40" mat-header-cell *matHeaderCellDef mat-sort-header>
        <mat-checkbox
          color="primary"
          (change)="doSelectAll()"
          [checked]="isAllChecked()"
        ></mat-checkbox>
      </th>
      <td mat-cell *matCellDef="let element">
        <mat-checkbox
          color="primary"
          [checked]="isChecked(element._id)"
          (change)="doSelect(element._id)"
        ></mat-checkbox>
      </td>
    </ng-container>
    <ng-container matColumnDef="name">
      <th width="200" mat-header-cell *matHeaderCellDef mat-sort-header>
        Name
      </th>
      <td mat-cell *matCellDef="let element">
        <h2>{{ element.name }}</h2>
      </td>
    </ng-container>
    <ng-container matColumnDef="dimension">
      <th width="300" mat-header-cell *matHeaderCellDef mat-sort-header>
        Max Supported Dim.
      </th>
      <td mat-cell *matCellDef="let element">{{ element.dimension }}</td>
    </ng-container>
    <ng-container matColumnDef="orientation">
      <th width="200" mat-header-cell *matHeaderCellDef mat-sort-header>
        Orientation
      </th>
      <td mat-cell *matCellDef="let element">{{ element.orientation }}</td>
    </ng-container>
    <ng-container matColumnDef="action">
      <th mat-header-cell *matHeaderCellDef mat-sort-header class="text-right">
        Action
      </th>
      <td mat-cell *matCellDef="let element" class="text-right">
        <button
          mat-button
          class="mat-button-custom"
          (click)="openDialog(element._id)"
        >
          <img src="assets/delete.png" alt="" width="16" />
        </button>
      </td>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
  </table>
</div>
<div class="clearfix"></div>
<app-paginate
  *ngIf="totalCount > 0"
  [length]="totalCount"
  pageSize="10"
  (onPageChange)="onPageChange($event)"
></app-paginate
>
