<app-breadcrumbs [crumbItems]="crumbItems"></app-breadcrumbs>
<app-module-header
  title="Add/Update Player Group"
  [isBackEnabled]="true"
  [showBulkMode]="false"
  [showAddButton]="false"
></app-module-header>
<form [formGroup]="myForm" (ngSubmit)="onSave()">
  <div class="row">
    <div class="col-xs-12">
      <app-alert
        *ngIf="alertMessage['message']"
        [message]="alertMessage['message']"
        [error]="alertMessage['error']"
      ></app-alert>
    </div>
  </div>
  <div class="row mt20">
    <div class="col-xs-4">
      <div class="row mt10 mb10">
        <div class="col-xs-12">
          <mat-form-field class="example-full-width">
            <input
              matInput
              placeholder="Name"
              name="name"
              formControlName="name"
              autocomplete="off"
            />
            <mat-error *ngIf="myForm.get('name').hasError('required')"
              >Field is required</mat-error
            >
            <mat-error *ngIf="myForm.get('name').hasError('minlength')"
              >Min length should be 5</mat-error
            >
          </mat-form-field>
        </div>
      </div>
      <div class="row mt10 mb10">
        <div class="col-xs-12">
          <mat-form-field>
            <mat-select
              placeholder="Location"
              name="location"
              formControlName="location"
            >
              <mat-option [value]="item.name" *ngFor="let item of locations">{{
                item.name
              }}</mat-option>
            </mat-select>
            <mat-error *ngIf="myForm.get('location').hasError('required')"
              >Field is required</mat-error
            >
          </mat-form-field>
        </div>
      </div>
      <div class="row mt10 mb10">
        <div class="col-xs-12">
          <mat-form-field class="example-full-width">
            <textarea
              matInput
              placeholder="Description"
              name="description"
              formControlName="description"
              maxlength="100"
            ></textarea>
            <mat-error *ngIf="myForm.get('description').hasError('required')"
              >Field is required</mat-error
            >
          </mat-form-field>
        </div>
      </div>
      <div class="row mt10 mb10">
        <div class="col-xs-12">
          <button mat-flat-button color="primary" [disabled]="!myForm.valid">
            {{ saveBtnText }}
          </button>
        </div>
      </div>
    </div>
    <div class="col-xs-8">
      <div class="assetWrapper">
        <div class="assetsListHeader">
          <h1>Add player to this group</h1>
          <button type="button" class="btnSecondary" (click)="showSceneModal()">
            Add
          </button>
        </div>
        <div class="clearfix"></div>
        <div class="tableWrapper">
          <table width="100%" mat-table [dataSource]="dataSource">
            <ng-container matColumnDef="name">
              <th width="250" mat-header-cell *matHeaderCellDef mat-sort-header>
                Name
              </th>
              <td mat-cell *matCellDef="let element">
                <h2>{{ element.name }}</h2>
              </td>
            </ng-container>
            <ng-container matColumnDef="dimension">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>
                Max Supported Dim.
              </th>
              <td mat-cell *matCellDef="let element">
                {{ element.dimension }}
              </td>
            </ng-container>
            <ng-container matColumnDef="location">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>
                Location
              </th>
              <td mat-cell *matCellDef="let element">{{ element.location }}</td>
            </ng-container>
            <ng-container matColumnDef="action">
              <th
                mat-header-cell
                *matHeaderCellDef
                mat-sort-header
                class="text-right"
              >
                Action
              </th>
              <td mat-cell *matCellDef="let element" class="text-right">
                <button
                  type="button"
                  mat-button
                  class="mat-button-custom"
                  (click)="onRemoveSelectePlayerItem(element)"
                >
                  <img src="assets/delete.png" alt="" width="16" />
                </button>
              </td>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
          </table>
        </div>
      </div>
    </div>
  </div>
</form>
