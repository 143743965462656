export const innerDrawerMenus = [
  {
    id: 8,
    label: "Analytics",
    path: "/dashboard/analytics",
    icon: "assets/ic-common.png",
  },
  {
    id: 13,
    label: "Scheduler",
    path: "/manage/scheduler",
    icon: "assets/ic-common.png",
  },
  {
    id: 5,
    label: "Media",
    path: "/dashboard/assets",
    icon: "assets/ic-common.png",
  },
];
