<app-breadcrumbs [crumbItems]="crumbItems"></app-breadcrumbs>
<app-module-header
  title="Update Player Details"
  [isBackEnabled]="true"
  [showBulkMode]="false"
  [showAddButton]="false"
></app-module-header>
<div class="app-form-wrapper">
  <div class="row">
    <div class="col-xs-6">
      <form [formGroup]="myForm" (ngSubmit)="save()">
        <div class="row mt10 mb10">
          <div class="col-xs-6">
            <mat-form-field class="example-full-width">
              <input
                matInput
                placeholder="Player Code"
                name="code"
                formControlName="code"
                autocomplete="off"
              />
              <mat-error *ngIf="myForm.get('code').hasError('required')"
                >Field is required</mat-error
              >
              <mat-error *ngIf="myForm.get('code').hasError('minlength')"
                >Min length should be 8</mat-error
              >
            </mat-form-field>
          </div>
        </div>
        <div class="row mt10 mb10">
          <div class="col-xs-6">
            <mat-form-field class="example-full-width">
              <input
                matInput
                placeholder="Name"
                name="name"
                formControlName="name"
                autocomplete="off"
              />
              <mat-error *ngIf="myForm.get('name').hasError('required')"
                >Field is required</mat-error
              >
              <mat-error *ngIf="myForm.get('name').hasError('minlength')"
                >Min length should be 5</mat-error
              >
            </mat-form-field>
          </div>
        </div>
        <div class="row mt10 mb10">
          <div class="col-xs-12">
            <mat-form-field>
              <mat-select
                placeholder="Location"
                name="location"
                formControlName="location"
              >
                <mat-option
                  [value]="item.name"
                  *ngFor="let item of locations"
                  >{{ item.name }}</mat-option
                >
              </mat-select>
              <mat-error *ngIf="myForm.get('location').hasError('required')"
                >Field is required</mat-error
              >
            </mat-form-field>
          </div>
        </div>
        <div class="row mt10 mb10">
          <div class="col-xs-6">
            <mat-form-field>
              <mat-select
                placeholder="Dimension"
                name="dimension"
                formControlName="dimension"
              >
                <mat-option
                  [value]="item.name"
                  *ngFor="let item of dimensions"
                  >{{
                    item.name +
                      " - " +
                      item.width +
                      "px by " +
                      item.height +
                      "px"
                  }}</mat-option
                >
              </mat-select>
              <mat-error *ngIf="myForm.get('dimension').hasError('required')"
                >Field is required</mat-error
              >
            </mat-form-field>
          </div>
          <div class="col-xs-6">
            <mat-form-field class="example-full-width">
              <input
                matInput
                placeholder="Storage Assigned"
                name="storage"
                formControlName="storage"
                autocomplete="off"
              />
              <span matSuffix>GB &nbsp;</span>
              <mat-error *ngIf="myForm.get('storage').hasError('required')"
                >Field is required</mat-error
              >
            </mat-form-field>
          </div>
        </div>

        <div class="row mt10 mb10">
          <div class="col-xs-6">
            <mat-form-field>
              <mat-select
                placeholder="Timezone"
                name="timezone"
                formControlName="timezone"
              >
                <mat-option
                  [value]="item"
                  *ngFor="let item of timezones"
                  >{{ item }}</mat-option
                >
              </mat-select>
              <mat-error *ngIf="myForm.get('timezone').hasError('required')"
                >Field is required</mat-error
              >
            </mat-form-field>
          </div>
        </div>
        <div class="row mt10 mb10">
          <div class="col-xs-12">
            <button mat-button color="primary" [disabled]="!myForm.valid">
              {{ saveBtnText }}
            </button>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>
