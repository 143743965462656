<link
  href="https://unpkg.com/video.js@7/dist/video-js.min.css"
  rel="stylesheet"
/>
<link
  href="https://unpkg.com/@videojs/themes@1/dist/city/index.css"
  rel="stylesheet"
/>
<div class="appFileViewer">
  <ngx-doc-viewer
    *ngIf="mimeType === 'application/pdf'"
    [url]="filePath"
    viewer="google"
    style="width: 100%; height: 60vh"
  ></ngx-doc-viewer>
  <img
    *ngIf="
      mimeType === 'image/jpeg' ||
      mimeType === 'image/jpg' ||
      mimeType === 'image/png'
    "
    [src]="filePath"
    (click)="open()"
    width="90%"
    height="auto"
  />
  <video
    id="my-video"
    class="video-js"
    controls
    preload="auto"
    width="100%"
    *ngIf="mimeType === 'video/mp4'"
  >
    <source [src]="filePath" type="video/mp4" />
  </video>
</div>